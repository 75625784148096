<template>
  <v-container fluid grid-list-md>
    <v-card max-width="1200" class="mx-auto pa-5">
      <v-card-title class="text-h5">
        {{ name }}
      </v-card-title>

      <v-card-header class="pa-5">
        Hier finden Sie eine Übersicht der Wettspiele, für die Sie als Referee eingetragen sind:
      </v-card-header>
      <!-- <v-carousel height="400" hide-delimiter-background hide-delimiters class="mx-auto" :continuous="false">
        <v-carousel-item v-for="competition in competitions" :key="competition.id" class="mx-auto">
          <v-container fill-height> -->
      <v-card v-for="competition in competitions" :key="competition.id" color="grey-lighten-1" height="320" width="1000"
        class="mx-auto ma-5">
        <v-row cols="12" class="mx-auto">
          <v-col class="mx-auto ma-5" cols="6">
            Wettspiel: {{ competition.name }} <br>
            Season: {{ competition.season }} <br><br>

            <section v-if="competition.organizer">
              Austräger: {{ competition.organizer.clubname }} <br>
              Adresse: {{ competition.organizer.strasse }} {{ competition.organizer.hausnummer }}, {{
                competition.organizer.plz }}, {{ competition.organizer.ort }}<br>
              Website: {{ competition.organizer.website }}<br><br>
            </section>
          </v-col>
          <v-col class="mx-auto ma-5" cols="6">
            Turnier Startdatum: {{ moment(competition.competitionStartDate, 'YYYY-MM-DD').format("DD.MM.YYYY") }}
            <br>
            Turnier Enddatum: {{ moment(competition.competitionEndDate, 'YYYY-MM-DD').format("DD.MM.YYYY") }}
            <br><br>
            Einsatzzeitraum Startdatum: {{ moment(competition.deploymentStartDate,
              'YYYY-MM-DD').format("DD.MM.YYYY")
            }} <br>
            Einsatzzeitraum Enddatum: {{ moment(competition.deploymentEndDate, 'YYYY-MM-DD').format("DD.MM.YYYY")
            }}
            <br><br>
            Referees:
            <ul>
              <li v-for="competition in competition.referees" v-bind:key="competition.id">{{ competition.username }}
              </li>
            </ul><br>
            Meldeschluss: {{ moment(competition.closingDate, 'YYYY-MM-DD').format("DD.MM.YYYY") }} <br><br>
          </v-col>
        </v-row>
      </v-card>
      <!-- </v-container>
        </v-carousel-item>
      </v-carousel> -->
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "MeineWettspiele",
  data() {
    return {
      name: "Meine Wettspiele",
      backend: process.env.VUE_APP_STRAPI_API_URL,
      isFormValid: false,
      competitions: [],
      userId: false,
    };
  },
  async created() {
    const axios = require("axios");

    // let yourDate = new Date().toISOString().split("T")[0];
    // let userId = "642e8d323326eebbcdc8408d"
    this.userId = this.parseJwt(localStorage.getItem("token")).id;

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      // strapi 3 filtering
      // https://docs-v3.strapi.io/developer-docs/latest/developer-resources/content-api/content-api.html#filters
      // we want to filter competitions / wettspiele that have already taken place
      // url: `${this.backend}/abrechnungens?_where[0][competition.competitionEndDate_lt]=2023-04-13`,

      // url: `${this.backend}/competitions?_where[0][referees.id_eq]=${userId}&_where[1][isPublic_eq]=true`,
      url: `${this.backend}/competitions?referees.id_eq=${this.userId}`,
      headers: {
        Authorization: localStorage.getItem("token") || "",
      },
    };

    axios
      .request(config)
      .then((response) => {
        this.competitions = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  watch: {
    competition(newValue) {
      this.refreshCompetitionInfo(newValue);
    },
  },
  computed: {
    hotelItems() {
      let result = [];
      if (this.competitions.length > 0) {
        this.competitions.forEach((element) => {
          result.push(element.name);
        });
      }
      return result.sort();

      //   let result = [];
      //   this.abrechnungen.forEach((element) => {
      //     result.push(element.competition.name);
      //   });
      //   return result.sort();
    },
  },
  method: {
    sendHotelReviewForm() {
      const options = {
        method: "POST",
        body: JSON.stringify({
          hotelId: this.hotelId,
          rating: this.rating,
          comment: this.comment,
        }),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };

      fetch("http://localhost:3000/register", options)
        // .then(response => response.json())
        .then((response) => {
          // console.debug(response)
          if (response.status === 200) {
            //
          } else if (response.status === 400) {
            //
          } else {
            //
          }
        })
        .catch((error) => {
          console.debug(error);
        });
    },
  },
};
</script>
